import React from 'react';

import AuthContext from '../../providers/AuthContext';
import LoginPage from './LoginPage';

const Auth: React.FC = ({ children }) => {
    return <>{AuthContext.isAuthenticated() ? children : <LoginPage />}</>;
};

export default Auth;

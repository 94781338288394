import React, { useCallback, useEffect, useRef, useState } from 'react';

import BaseReport from './BaseReport';
import DataTable from '../chart/DataTable/DataTable';
import ReportsService from '../../services/ReportsService';
import DataTransformer from '../../utilities/DataTransformer';
import NumberFormatter from '../../utilities/NumberFormatter';
import SortingUtility from '../../utilities/SortingUtility';
import TableChartData from '../../models/TableChartData';
import ReportData from '../../models/ReportData';
import Report from './Report';
import ValueFilter from './ValueFilter';

const DataTableReport: React.FC<Report> = (
    {
        reportCode,
        brokerId,
        brokerReport,
        title,
        defaultFilter,
        filterOptions
    }
) => {
    const [rawData, setRawData] = useState<ReportData>([]);
    const [data, setData] = useState<TableChartData[]>([]);
    const [valueFilter, setValueFilter] = useState<string>(defaultFilter || ValueFilter.Yearly);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const mountedRef = useRef(false);

    const getData = useCallback(() => {
        const filterValue = valueFilter === ValueFilter.Percentage ? defaultFilter ?? '' : valueFilter;

        return ReportsService.get(reportCode, brokerId)
            .then(handleRawReportData)
            .then((report) => DataTransformer.toTableChartData(report, filterValue))
            .then(SortingUtility.sortTableData)
            .then((data) => {
                mountedRef.current && setData(data);
            })
            .finally(() => mountedRef.current && setIsLoading(false));
    }, [brokerId, defaultFilter, reportCode]);

    const handleRawReportData = (report: ReportData): ReportData => {
        mountedRef.current && setRawData(report);

        return report;
    };

    const getAdditionalHeader = (): string => {
        const sumData = brokerReport ? data[0] : data[1];
        const sum = DataTransformer.toDataValueSum(sumData);

        return `Gesamt: ${NumberFormatter.format(sum, 2)} €`;
    };

    const renderAdditionalHeader = (): boolean => {
        return !isLoading && !!data.length && valueFilter !== ValueFilter.Percentage;
    };

    const handleFilterChange = (value: string) => {
        if (mountedRef.current) {
            const filterValue = value === ValueFilter.Percentage ? defaultFilter ?? '' : value;
            setData(SortingUtility.sortTableData(DataTransformer.toTableChartData(rawData, filterValue)));
            setValueFilter(value);
        }
    };

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <BaseReport
            title={title}
            additionalHeader={renderAdditionalHeader() ? getAdditionalHeader() : ''}
            reportCode={reportCode}
            data={brokerReport ? data[0] : data[1]}
            getCallback={getData}
            type="table"
            filterValue={valueFilter}
            filterOptions={filterOptions}
            onFilterChange={handleFilterChange}
        >
            <DataTable data={brokerReport ? data[0] : data[1]} percentage={valueFilter === ValueFilter.Percentage} />
        </BaseReport>
    );
};

export default DataTableReport;

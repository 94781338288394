import React, { ReactNode, useEffect, useState } from 'react';

import { ResponsiveBar } from '@nivo/bar';
import EmptyData from '../../EmptyData/EmptyData';
import ChartDefaults from '../ChartDefaults';
import StackedBarData from '../../../models/StackedBarData';
import NumberFormatter from '../../../utilities/NumberFormatter';
import DateFormatter from '../../../utilities/DateFormatter';
import { Colors, RestItemsLabel } from '../../../constants';

export const translations: Record<string, string> = {
    CtrG18: 'Altersgruppe 18-29',
    CtrG30: 'Altersgruppe 30-39',
    CtrG40: 'Altersgruppe 40-49',
    CtrG50: 'Altersgruppe 50-59',
    CtrG60: 'Altersgruppe 60+',
    CstG18: 'Altersgruppe 18-29',
    CstG30: 'Altersgruppe 30-39',
    CstG40: 'Altersgruppe 40-49',
    CstG50: 'Altersgruppe 50-59',
    CstG60: 'Altersgruppe 60+',
    CNN: 'N - Beitragsfrei',
    CNR: 'R - Beitragsfrei (DV)',
    CNV: 'V - Übertragung Komplettbestand',
    CNU: 'U - Bestandsübertragung',
    CNK: 'K - Korrespondenzmaklerschaft',
    CNH: 'H - Aktiver Vertrag Honorarberatung',
    CNE: 'E - Beantragt (DV)',
    CNB: 'B - Beantragt ohne Policeneingang',
    CNA: 'A - Aktiver Vertrag',
    CNF: 'F - Aktiver Vertrag (DV)',
    BC: 'Gewerbekunden',
    PC: 'Privatkunden',
    female: 'weiblich',
    male: 'männlich',
    LVSS: 'Leben',
    KrSS: 'Kranken',
    KoSS: 'Komposit'
};

const transformTooltip = (
    seriesNames: string[],
    data: any[],
    valueSuffix: string,
    roundDigits: boolean,
    averageSum: boolean
): { items: ReactNode; total: string }[] => {
    const namesOfSeries = [...seriesNames].reverse();
    return data?.map((datum: Record<string, any>) => {
        const keys = Object.keys(datum).filter((k) => k !== 'name');
        let total = namesOfSeries.reduce((total, key) => total + (datum[key] || 0), 0);
        if (keys.length && averageSum) {
            total = total / keys.length;
        }

        return {
            items: namesOfSeries
                .map((serie, i) =>
                    datum[serie] ? (
                        <li key={serie}>
                            <span className="drm--color" style={{ backgroundColor: Colors[keys.length - 1 - i] }} />
                            <span className="drm--text">
                                {translations[serie] || serie}:{' '}
                                {NumberFormatter.format(datum[serie], roundDigits ? 2 : undefined)}
                                {valueSuffix}
                            </span>
                        </li>
                    ) : null
                )
                .filter((item) => !!item),
            total: NumberFormatter.format(total, roundDigits ? 2 : undefined)
        };
    });
};

const extractAllSeries = (data: StackedBarData) => {
    const result: string[] = [];
    let hasSum = false;
    data?.forEach((category) => {
        category.series.forEach((serie) => {
            if (serie.name === RestItemsLabel) {
                hasSum = true;
            } else if (!result.includes(serie.name)) {
                result.push(serie.name);
            }
        });
    });

    hasSum && result.push(RestItemsLabel);

    // We reverse the array because we want the first item to be shown on top,
    // and the responsive bar chart by default displays the first item at the bottom
    return result.reverse();
};

const transformData = (seriesNames: string[], data: StackedBarData, percentage: boolean) => {
    return data?.map((category) => {
        const data: any = { name: DateFormatter.formatForChart(category.category) };
        seriesNames.forEach((serieName) => {
            const serie = category.series.find((serie) => serie.name === serieName);
            data[serieName] = serie ? (percentage ? serie.value * 100 : serie.value) : 0;
        });

        return data;
    });
};

type StackedBarProps = {
    data: StackedBarData;
    valueSuffix?: string;
    roundDigits?: boolean;
    percentage?: boolean;
    averageSum?: boolean;
};

const StackedBarChart: React.FC<StackedBarProps> = (
    {
        data,
        valueSuffix = '',
        roundDigits = false,
        percentage = false,
        averageSum = false
    }
) => {
    const [max, setMax] = useState<number>(100);

    useEffect(() => {
        const maxValue = data?.reduce((max, category) => {
            return Math.max(
                max,
                category.series.reduce((total, block) => total + Number(block.value), 0)
            );
        }, 0);

        setMax(maxValue * 1.1 * (percentage ? 100 : 1));
    }, [data, percentage]);

    const StackedBarChartTooltip: React.FC<Record<string, any>> = ({ index, tooltipData }) => {
        setTimeout(() => {
            document.querySelectorAll('.dr-chart-tooltip').forEach((tooltipEl) => {
                tooltipEl.parentElement?.classList.add('dr-chart-tooltip-wrapper');
            });
        }, 10);

        return (
            <div className={'dr-chart-tooltip ' + (index >= tooltipData.length / 2 - 1 ? 'drm--left' : 'drm--right')}>
                <ul>{tooltipData[index].items}</ul>
                <footer>
                    {averageSum ? 'Durchschnitt' : 'Gesamt'}: {tooltipData[index].total}
                    {valueSuffix}
                </footer>
            </div>
        );
    };

    if (!data?.length) {
        return <EmptyData />;
    }

    const seriesNames = extractAllSeries(data);
    const transformedData = transformData(seriesNames, data, percentage);
    const tooltipData = transformTooltip(seriesNames, transformedData, valueSuffix, roundDigits, averageSum);

    // noinspection RequiredAttributes
    return (
        <ResponsiveBar
            role="application"
            indexBy="name"
            keys={seriesNames}
            data={transformedData}
            padding={0.3}
            enableGridX={true}
            enableLabel={false}
            valueScale={{ type: 'linear', min: 0, max: max }}
            indexScale={{ type: 'band', round: true }}
            tooltip={(props) => <StackedBarChartTooltip tooltipData={tooltipData} {...props} />}
            {...ChartDefaults(max, percentage)}
        />
    );
};

export default StackedBarChart;

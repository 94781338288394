import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageBarReport from '../../components/PageBarReport';

const VerteilungNachGeschlecht = () => {
    return (
        <PageBarReport
            title="Verteilung nach Geschlecht"
            reportCode={ReportCodes.DistributionPerSex}
            percentage
            roundDigits
        />
    );
};

export default VerteilungNachGeschlecht;

import React from 'react';

import BasePage from '../../components/pages/BasePage/BasePage';
import AltersdurchschnittKunden from './components/AltersdurchschnittKunden';
import SimpleQuote from './components/simple-quote';
import AnzahlAktiverKunden from './components/AnzahlAktiverKunden';
import VerhaltnisKundenPrivatGewerbe from './components/VerhaltnisKundenPrivatGewerbe';
import AVGKundenScoring from './components/AVGKundenScoring';
import KundenScoring from './components/KundenScoring';
import VerteilungNachGeschlecht from './components/VerteilungNachGeschlecht';

const KundenPage = () => {
    return (
        <BasePage title="Kunden">
            <AltersdurchschnittKunden />
            <div className="drt--two-columns">
                <SimpleQuote />
                <AnzahlAktiverKunden />
            </div>
            <VerhaltnisKundenPrivatGewerbe />
            <div className="drt--two-columns">
                <AVGKundenScoring />
                <KundenScoring />
            </div>
            <VerteilungNachGeschlecht />
        </BasePage>
    );
};

export default KundenPage;

import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageNumberReport from '../../components/PageNumberReport';

const Besicherungsquote = () => {
    return (
        <PageNumberReport
            title="Besicherungsquote"
            reportCode={ReportCodes.CollateralizationQuota}
            valueSuffix="%"
            roundDigits
        />
    );
};

export default Besicherungsquote;

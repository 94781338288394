import React from 'react';
import Broker from '../../../models/Broker';

export type BaseContextType = {
    brokerCache: Record<string, Broker>;
    brokerReport: boolean;
    brokerId: string;
    reportingYears: number[];
    setBrokerCache: (value: Record<string, any>) => void;
    setBrokerReport: (value: boolean) => void;
    setBrokerId: (value: string) => void;
    setReportingYears: (value: number[]) => void;
};

export const BasePageContext = React.createContext<BaseContextType>({
    brokerCache: {},
    brokerReport: true,
    brokerId: '',
    reportingYears: [],
    setBrokerCache: () => {
        throw Error('setBrokerCache not implemented in Context Provider');
    },
    setBrokerReport: () => {
        throw Error('setBrokerReport not implemented in Context Provider');
    },
    setBrokerId: () => {
        throw Error('setBrokerId not implemented in Context Provider');
    },
    setReportingYears: () => {
        throw Error('setReportingYears not implemented in Context Provider');
    }
});

export const usePageContext = (): BaseContextType => {
    return React.useContext(BasePageContext);
};

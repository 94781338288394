import React, { useCallback, useEffect, useRef, useState } from 'react';

import StackedBarData from '../../models/StackedBarData';
import Report from './Report';
import ReportsService from '../../services/ReportsService';
import DataTransformer from '../../utilities/DataTransformer';
import StackedBarChart from '../chart/StackedBarChart/StackedBarChart';
import BaseReport from './BaseReport';

const BarReport: React.FC<Report> = (
    {
        reportCode,
        brokerId,
        brokerReport,
        title,
        valueSuffix,
        defaultFilter,
        filterOptions,
        ...props
    }
) => {
    const [data, setData] = useState<StackedBarData[]>([]);
    const [yearFilter, setYearFilter] = useState<string>(defaultFilter || '');

    const mountedRef = useRef(false);

    const getData = useCallback(() => {
        return ReportsService.get(reportCode, brokerId, { year: yearFilter })
            .then(DataTransformer.toStackedBarData)
            .then((data) => {
                mountedRef.current && setData(data);
            });
    }, [brokerId, reportCode, yearFilter]);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <BaseReport
            title={title}
            reportCode={reportCode}
            data={brokerReport ? data[0] : data[1]}
            getCallback={getData}
            type="chart"
            filterValue={yearFilter}
            filterOptions={filterOptions}
            onFilterChange={(year) => {
                mountedRef.current && setYearFilter(year);
            }}
        >
            <StackedBarChart
                data={brokerReport ? data[0] : data[1]}
                valueSuffix={props.percentage ? '%' : valueSuffix}
                {...props}
            />
        </BaseReport>
    );
};

export default BarReport;

import React from 'react';
import MainHeader from './MainHeader';
import Sidebar from './Sidebar';

const Layout: React.FC = ({ children }) => {
    return (
        <div className="dr-app">
            <MainHeader />
            <Sidebar />
            {children}
        </div>
    );
};

export default Layout;

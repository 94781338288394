import React from 'react';

import BasePage from '../../components/pages/BasePage/BasePage';
import VerteilungNachSpartenbereiche from './components/VerteilungNachSpartenbereiche';
import VerteilungNachSpartenKV from './components/VerteilungNachSpartenKV';
import VerteilungNachSpartenLV from './components/VerteilungNachSpartenLV';
import VerteilungNachSpartenSach from './components/VerteilungNachSpartenSach';

const SpartenPage = () => {
    return (
        <BasePage title="Sparten">
            <VerteilungNachSpartenbereiche />
            <VerteilungNachSpartenKV />
            <VerteilungNachSpartenLV />
            <VerteilungNachSpartenSach />
        </BasePage>
    );
};

export default SpartenPage;

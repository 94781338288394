import BaseService from './BaseService';
import Integration from '../models/Integration';
import AuthContext from '../providers/AuthContext';

const IntegrationsService = {
    get: (): Promise<Integration[]> => {
        const service = BaseService(process.env.REACT_APP_INTEGRATIONS_API_URL, false);

        return service
            .get('/me/global', {
                Authorization: 'Bearer ' + AuthContext.getUser().authInfo.access_token
            })
            .then((response: { integrations: Integration[] }) => response.integrations)
            .catch((error: any) => Promise.reject(error.response?.data || error));
    }
};

export default IntegrationsService;

import React, { useEffect, useState } from 'react';
import { PointTooltipProps, ResponsiveLine, Serie } from '@nivo/line';

import { Line2D, Point2D } from '../../../models/LineChartData';
import ChartDefaults from '../ChartDefaults';
import EmptyData from '../../EmptyData/EmptyData';
import NumberFormatter from '../../../utilities/NumberFormatter';
import DateFormatter from '../../../utilities/DateFormatter';

type LineChartProps = {
    data: Line2D;
    average?: Line2D;
    roundDigits?: boolean;
    valueSuffix?: string;
    percentage?: boolean;
};

const LineChart: React.FC<LineChartProps> = ({ data, average, roundDigits = false, valueSuffix, percentage }) => {
    const [max, setMax] = useState<number>(100);

    const transformData = (): Serie[] => {
        const chartData: Serie[] = data?.data
            ? [
                {
                    id: data.name,
                    data: getPointsCoordinates(data.data)
                }
            ]
            : [];

        average && chartData.push({
            id: average.name,
            data: getPointsCoordinates(average.data)
        });

        return chartData;
    };

    const getPointsCoordinates = (data: Point2D[]): { x: string, y: string }[] => {
        return data.map(item => ({
            x: DateFormatter.formatForChart(item.x),
            y: percentage ? item.y * 100 : item.y
        }));
    };

    useEffect(() => {
        const dataMaxValue = data?.data.reduce((innerMax, point) => Math.max(innerMax, point.y), 0);
        const averageMaxValue = average?.data.reduce((innerMax, point) => Math.max(innerMax, point.y), 0);
        const maxValue = (averageMaxValue && averageMaxValue > dataMaxValue) ? averageMaxValue : dataMaxValue;

        setMax(maxValue * 1.1 * (percentage ? 100 : 1));
    }, [average, data, percentage]);

    if (!data?.data?.length) {
        return <EmptyData />;
    }

    return (
        <ResponsiveLine
            data={transformData()}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: max, stacked: false }}
            pointSize={8}
            pointBorderWidth={0}
            pointLabelYOffset={-12}
            useMesh={true}
            enableCrosshair={false}
            tooltip={(props) => (
                <Tooltip
                    maxItems={transformData().length}
                    valueSuffix={valueSuffix}
                    roundDigits={roundDigits}
                    {...props}
                />
            )}
            legends={average && [
                {
                    anchor: 'top',
                    direction: 'row',
                    itemHeight: 20,
                    itemWidth: 100,
                    itemsSpacing: 10,
                    symbolSize: 10,
                    itemTextColor: '#000',
                    translateX: 0,
                    translateY: -32
                }
            ]}
            {...ChartDefaults(max, percentage)}
        />
    );
};

const Tooltip: React.FC<PointTooltipProps & { maxItems: number; roundDigits?: boolean; valueSuffix?: string }> = (
    {
        point,
        maxItems,
        roundDigits,
        valueSuffix
    }
) => {
    const { id, serieId, data } = point;

    const position = Number(id.replace(serieId + '.', ''));

    return (
        <div
            className={
                'dr-chart-tooltip drm--small ' + (position > Math.ceil(maxItems / 2) ? 'drm--left' : 'drm--right')
            }>
            <span className="drm--text">
                {NumberFormatter.format(data.y as string, roundDigits ? 2 : undefined)}
                {valueSuffix}
            </span>
        </div>
    );
};

export default LineChart;

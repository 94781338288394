import React, { useRef, useState } from 'react';
import { useClickAway, useMount } from 'react-use';
import AuthContext from '../../providers/AuthContext';
import OAuthService from '../../services/OAuthService';
import IntegrationsService from '../../services/IntegrationsService';
import Integration from '../../models/Integration';

const UserMenu: React.FC = () => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showIntegrations, setShowIntegrations] = useState<boolean>(false);
    const [integrations, setIntegrations] = useState<Integration[]>([]);

    const menuRef = useRef<HTMLUListElement>(null);
    const integrationsMenuRef = useRef<HTMLUListElement>(null);
    const user = AuthContext.getUser();

    const logout = () => {
        AuthContext.logout();
        OAuthService.logout(user.authInfo.id_token);
    };

    useClickAway(menuRef, () => setShowMenu(false));

    useClickAway(integrationsMenuRef, () => setShowIntegrations(false));

    useMount(() => {
        IntegrationsService.get().then(setIntegrations);
    });

    return (
        <div className="dr-user-menu">
            <button className="drp-integrations-menu" onClick={() => setShowIntegrations(true)}>
                <svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M192 176c0 26.5-21.5 48-48 48H48c-26.51 0-48-21.5-48-48V80c0-26.51 21.49-48 48-48h96c26.5 0 48 21.49 48 48v96Zm0 256c0 26.5-21.5 48-48 48H48c-26.51 0-48-21.5-48-48v-96c0-26.5 21.49-48 48-48h96c26.5 0 48 21.5 48 48v96Zm64-352c0-26.51 21.5-48 48-48h96c26.5 0 48 21.49 48 48v96c0 26.5-21.5 48-48 48h-96c-26.5 0-48-21.5-48-48V80Zm192 352c0 26.5-21.5 48-48 48h-96c-26.5 0-48-21.5-48-48v-96c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96Z"></path>
                </svg>
                {showIntegrations && (
                    <ul ref={integrationsMenuRef} className="drp-integrations-list">
                        {integrations.map((item) => {
                            return (
                                <li key={item.id}>
                                    <a href={item.web.staticUri} target="_blank" className="drp-icon" rel="noreferrer">
                                        <img src={item.iconUri} alt={item.title} />
                                        {item.title}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </button>
            <img className="drp-avatar" alt="user" src={user.pictureUrl} onClick={() => setShowMenu(true)} />
            {showMenu && (
                <ul ref={menuRef} className="drp-user-menu">
                    <li onClick={logout}>Logout</li>
                </ul>
            )}
        </div>
    );
};

export default UserMenu;

import React from 'react';

import ValueFilter from '../../../components/reports/ValueFilter';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageDataTableReport from '../../components/PageDataTableReport';

export const ValueFilterOptions: { value: string, label: string }[] = [
    { value: ValueFilter.Yearly, label: 'Jahresnettoprämie' },
    { value: ValueFilter.Monthly, label: 'Monatsbeitrag' },
    { value: ValueFilter.Evaluation, label: 'Bewertungssumme' },
    { value: ValueFilter.Percentage, label: 'Prozentsatz' }
];

const VerteilungDerGesellschaftenKV = () => {
    const filterOptions = [...ValueFilterOptions].filter(o => o.value !== ValueFilter.Evaluation);

    return (
        <PageDataTableReport
            title="Verteilung der Gesellschaften KV"
            reportCode={ReportCodes.DistributionOfCompaniesInHealth}
            defaultFilter={ValueFilter.Monthly}
            filterOptions = {filterOptions}
        />
    );
};

export default VerteilungDerGesellschaftenKV;

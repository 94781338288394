import { Months } from '../constants';

const DateFormatter = {
    formatForChart: (input: string): string => {
        const [year, month] = input.split('-');

        return Months[Number(month) - 1] + ' \'' + Number(year).toString().substring(2);
    }

};

export default DateFormatter;

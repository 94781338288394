import React from 'react';

import logo from './logo.svg';
import UserMenu from '../UserMenu/UserMenu';

const MainHeader: React.FC = () => {
    return (
        <header>
            <img src={logo} alt="AMEISE Analytics" />
            <UserMenu />
        </header>
    );
};

export default MainHeader;

import React, { useState } from 'react';

import { useMount } from 'react-use';
import { usePageContext } from './BasePageContext';
import BrokerSearch from '../../BrokerSearch/BrokerSearch';
import Checkbox from '../../form/Checkbox/Checkbox';
import Loader from '../../loader/Loader';
import AuthContext from '../../../providers/AuthContext';
import ReportsService from '../../../services/ReportsService';

type Props = {
    title: string;
};

const BasePage: React.FC<Props> = ({ title, children }) => {
    const { reportingYears, brokerReport, setBrokerReport, setReportingYears, setBrokerId } = usePageContext();
    const [isInitializing, setIsInitializing] = useState(true);

    const onChecked = () => {
        setBrokerReport(!brokerReport);
    };

    const onBrokerChanged = (brokerId: string) => {
        const user = AuthContext.getUser();
        user.activeBrokerId = brokerId;
        AuthContext.setUser(user);
        setBrokerId(brokerId);
    };

    const getReportingYears = () => {
        if (!reportingYears.length) {
            return ReportsService.getReportingYears().then(setReportingYears).finally(() => setIsInitializing(false));
        }

        setIsInitializing(false);
    };

    useMount(getReportingYears);

    if (isInitializing) {
        return <Loader />;
    }

    return (
        <main>
            <header>
                <h1>{title}</h1>
                <div className="dr-filters-wrapper">
                    <Checkbox label="Untervermittler anzeigen" checked={!brokerReport} onChecked={onChecked} />
                    <div className="drp-broker-selector">
                        <BrokerSearch
                            selectedBrokerId={AuthContext.getUser()?.activeBrokerId || AuthContext.getUser()?.id || ''}
                            onChange={onBrokerChanged} />
                        {process.env.REACT_APP_MOCK_BROKER === 'yes' && (
                            <select
                                value={AuthContext.getUser()?.id}
                                onChange={(e) => {
                                    const user = AuthContext.getUser();
                                    user.id = e.currentTarget.value;
                                    user.activeBrokerId = e.currentTarget.value;
                                    AuthContext.setUser(user);
                                    window.location.reload();
                                }}
                                style={{
                                    background: '#ffffff',
                                    border: '1px solid #CBD5E0',
                                    borderRadius: '4px',
                                    height: '36px',
                                    marginBottom: '10px',
                                    padding: '4px',
                                    color: '#4A5568',
                                    fontSize: '14px'
                                }}>
                                <option id="">- SELECT TEST BROKER -</option>
                                <option id="Root">Root</option>
                                <option id="Child1">Child1</option>
                                <option id="3321">3321</option>
                                <option id="009L3T">007127</option>
                                <option id="009L3T">009L3T</option>
                                <option id="00PT0W">00PT0W</option>
                                <option id="009994">009994</option>
                                <option id="417216">417216</option>
                                <option id="006420">006420</option>
                                <option id="88CA64">88CA64</option>
                            </select>
                        )}
                    </div>
                </div>
            </header>
            {children}
        </main>
    );
};

export default BasePage;

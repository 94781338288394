import React from 'react';

import Footer from './Footer/Footer';
import { ReportFilter } from '../reports/Report';

type Props = ReportFilter & {
    header: string;
    additionalHeader?: string;
    reportCode: string;
    type?: 'chart' | 'table' | 'number';
    data?: any;
    average?: any;
    bodyClassName?: string;
};

const ChartSection: React.FC<Props> = (
    {
        header,
        additionalHeader,
        reportCode,
        type = 'chart',
        data,
        average,
        bodyClassName = '',
        children,
        filterValue,
        filterOptions,
        onFilterChange
    }
) => {
    return (
        <section className={'drt--' + type}>
            <header>
                <span>{header}</span>
                <span>{additionalHeader}</span>
            </header>
            <div className={`drp-body ${bodyClassName}`}>{children}</div>
            {data && <Footer
                type={type}
                reportCode={reportCode}
                data={data}
                average={average}
                filterValue={filterValue}
                filterOptions={filterOptions}
                onFilterChange={onFilterChange}
            />}
        </section>
    );
};

export default ChartSection;

import React, { useCallback, useEffect, useRef, useState } from 'react';

import ReportsService from '../../services/ReportsService';
import DataTransformer from '../../utilities/DataTransformer';
import NumberChart from '../chart/NumberChart/NumberChart';
import BaseReport from './BaseReport';
import Report from './Report';

const NumberReport: React.FC<Report> = ({ reportCode, brokerId, brokerReport, title, ...props }) => {
    const [data, setData] = useState<number[]>([0, 0]);

    const mountedRef = useRef(false);

    const getData = useCallback(() => {
        return ReportsService.get(reportCode, brokerId)
            .then((data) => DataTransformer.toNumberChartData(data))
            .then((data) => {
                mountedRef.current && setData(data);
            });
    }, [brokerId, reportCode]);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <BaseReport title={title} reportCode={reportCode} getCallback={getData} type="number">
            <NumberChart number={brokerReport ? data[0] : data[1]} {...props} />
        </BaseReport>
    );
};

export default NumberReport;

import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageBarReport from '../../components/PageBarReport';

const VerteilungNachSpartenSach = () => {
    return (
        <PageBarReport
            title="Verteilung nach Sparten Komposit"
            reportCode={ReportCodes.DistributionPerSubSectionsForPropertyInsurances}
        />
    );
};

export default VerteilungNachSpartenSach;

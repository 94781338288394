import React from 'react';

import NumberFormatter from '../../../utilities/NumberFormatter';
import DataTransformer from '../../../utilities/DataTransformer';
import TableChartData from '../../../models/TableChartData';
import EmptyData from '../../EmptyData/EmptyData';

type TableChartProps = {
    data: TableChartData;
    percentage?: boolean;
};

const DataTable: React.FC<TableChartProps> = ({ data, percentage }) => {
    const sum = DataTransformer.toDataValueSum(data);
    const formatDataValue = (value: any): any => {
        return percentage ? (value * 100) / sum : value;
    };

    if (!data?.length) {
        return <EmptyData />;
    }

    return (
        <table className="dr-data-table">
            <tbody>
            {data?.map((stat: any, index: number) => {
                return (
                    <tr key={index} className="drp-row">
                        <td className="drp-order-number">{index + 1}</td>
                        <td className="drp-name">{stat.name}</td>
                        <td className="drp-value">
                            <span>{NumberFormatter.format(formatDataValue(stat.value), 2)}</span>
                            <span className="drm--currency">{percentage ? '%' : '€'}</span>
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default DataTable;

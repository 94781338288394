import React, { useState } from 'react';

import { BaseContextType, BasePageContext } from './BasePageContext';
import Broker from '../../../models/Broker';

const BasePageProvider = (props: any) => {
    const [brokerCache, setBrokerCache] = useState<Record<string, Broker>>({});
    const [brokerReport, setBrokerReport] = useState<boolean>(true);
    const [brokerId, setBrokerId] = useState<string>('');
    const [reportingYears, setReportingYears] = useState<number[]>([]);

    const providerValue: BaseContextType = {
        brokerCache,
        brokerReport,
        brokerId,
        reportingYears,
        setBrokerCache,
        setBrokerReport,
        setBrokerId,
        setReportingYears
    };

    return <BasePageContext.Provider value={providerValue}>{props.children}</BasePageContext.Provider>;
};

export default BasePageProvider;

import React from 'react';

import { PageReport } from '../../components/reports/Report';
import { usePageContext } from '../../components/pages/BasePage/BasePageContext';
import BarReport from '../../components/reports/BarReport';

const PageBarReport: React.FC<PageReport> = (props) => {
    const { brokerReport, brokerId, reportingYears } = usePageContext();
    const currentYear: number = new Date().getFullYear();
    const defaultYear = reportingYears.includes(currentYear) ? currentYear.toString() : reportingYears[0]?.toString();
    const filterOptions = reportingYears.map((year) => ({
        value: year.toString(),
        label: year === currentYear ? 'Aktuelles Jahr' : year === currentYear - 1 ? 'Letztes Jahr' : year.toString()
    }));

    return <BarReport
        brokerId={brokerId}
        brokerReport={brokerReport}
        defaultFilter={defaultYear}
        filterOptions={filterOptions}
        {...props}
    />;
};

export default PageBarReport;

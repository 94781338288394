import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageLineReport from '../../components/PageLineReport';

const MahnquoteVonVertragenImHaftungszeitraum = () => {
    return (
        <PageLineReport
            title="Mahnquote von Verträgen im Haftungszeitraum"
            reportCode={ReportCodes.AdmonitionQuote}
            roundDigits
        />
    );
};

export default MahnquoteVonVertragenImHaftungszeitraum;

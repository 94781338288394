import React from 'react';

const Page404: React.FC = () => {
    return (
        <main>
            <header>
                <h1>Page not found (404)</h1>
            </header>
            <div>The page you are trying to access does not exist. Please use the menu in the sidebar.</div>
        </main>
    );
};

export default Page404;

import React, { useEffect, useState } from 'react';

import Loader from '../loader/Loader';
import ChartSection from '../chart/ChartSection';
import AuthContext from '../../providers/AuthContext';
import { ReportFilter } from './Report';

type Props = ReportFilter & {
    type: 'chart' | 'table' | 'number';
    title: string;
    reportCode: string;
    getCallback: () => Promise<void>;
    data?: any;
    average?: any;
    additionalHeader?: string;
};

const BaseReport: React.FC<Props> = (
    {
        type,
        title,
        reportCode,
        getCallback,
        data,
        average,
        children,
        filterValue,
        filterOptions,
        onFilterChange,
        additionalHeader
    }
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (!getCallback) {
            return;
        }

        setIsLoading(true);
        getCallback()
            .catch((error: any) => {
                if (error?.status === 401) {
                    AuthContext.logout();
                    window.location.reload();
                } else {
                    setError('Error fetching the report data: ' + (error?.message || 'unknown error.'));
                    console.error(error);
                }
            })
            .finally(() => setIsLoading(false));
    }, [getCallback]);

    return (
        <ChartSection
            header={title}
            additionalHeader={additionalHeader}
            reportCode={reportCode}
            type={type}
            data={data}
            average={average}
            bodyClassName={error ? 'drs--error' : ''}
            filterValue={filterValue}
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
        >
            {isLoading ? <Loader /> : error ? <span className="drp-error">{error}</span> : children}
        </ChartSection>
    );
};

export default BaseReport;

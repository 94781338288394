import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

type Props = {
    prefix?: string;
    value?: string;
    options: {
        label: string;
        value: string;
    }[];
    onChange: (value: string) => void;
    breakAfterOption?: number;
};

const SelectBox: React.FC<Props> = ({ prefix = '', value, options, onChange, breakAfterOption = null }) => {
    const [opened, setOpened] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLUListElement>(null);

    useClickAway(ref, () => setOpened(false));

    useEffect(() => {
        if (!optionsRef.current) {
            return;
        }

        const dropdownBottom = optionsRef.current.getBoundingClientRect().bottom;
        const viewportHeight = window.innerHeight;
        dropdownBottom > viewportHeight && optionsRef.current.scrollIntoView({
            block: 'end',
            inline: 'nearest',
            behavior: 'smooth'
        });
    }, [opened]);

    return (
        <div ref={ref} className={'dr-select-box' + (opened ? ' lgs--opened' : '')}>
            <button onClick={() => setOpened((prevState) => !prevState)}>
                <span>{prefix + options.find((o) => o.value === value)?.label}</span>
                <svg className="drp-icon">
                    <path d="M6 9L12 15L18 9" />
                </svg>
            </button>
            <ul ref={optionsRef}>
                {options.map((option, index) => (
                    <Fragment key={option.value}>
                        <li
                            className={option.value === value ? 'lgs--active' : ''}
                            onClick={() => {
                                onChange(option.value);
                                setOpened(false);
                            }}>
                            {option.label}
                        </li>
                        {!!breakAfterOption && breakAfterOption === ++index && <span className={'drp-option-break'} />}
                    </Fragment>
                ))}
            </ul>
        </div>
    );
};

export default SelectBox;

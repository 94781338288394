enum ReportCodes {
    BrokerNumber = 'untervermittler-number',
    CollateralizationQuota = 'collateralization-quote',
    LiabilityVolume = 'liability-volume',
    AdmonitionQuote = 'admonition-quote-of-all-contracts-in-the-period-of-liability',
    CancellationQuote = 'cancellation-quote-in-the-period-of-liability',
    DistributionPerContractStatus = 'distribution-per-contract-status',
    ContractVolumeOfPrivateCare = 'contract-volume-of-private-care',
    DistributionOfCompaniesInHealth = 'distribution-of-the-insurance-companies-in-health-insurances',
    DistributionOfCompaniesInLife = 'distribution-of-the-insurance-companies-in-life-insurances',
    DistributionOfCompaniesInProperty = 'distribution-of-the-insurance-companies-in-the-property-liabilities',
    CustomerAge = 'customer-age',
    NumberOfActiveCustomers = 'number-of-active-customers',
    AvgCustomerScoring = 'avg-customer-scoring',
    CustomerScoring = 'customer-scoring',
    SimpleQuote = 'simple-quote',
    CustomerDistributionPrivateBusiness = 'customer-distribution-private-business',
    DistributionPerSex = 'distribution-per-sex',
    DistributionPerSectionAreas = 'distribution-per-section-areas',
    DistributionPerSubSectionsForHealthInsurances = 'distribution-per-sub-sections-for-health-insurances',
    DistributionPerSubSectionsForLifeInsurances = 'distribution-per-sub-sections-for-life-insurances',
    DistributionPerSubSectionsForPropertyInsurances = 'distribution-per-sub-sections-for-property-insurances'
}

export default ReportCodes;

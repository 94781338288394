import React from 'react';

import BasePage from '../../components/pages/BasePage/BasePage';
import VerteilungDerGesellschaftenKV from './components/VerteilungDerGesellschaftenKV';
import VerteilungDerGesellschaftenLV from './components/VerteilungDerGesellschaftenLV';
import VerteilungDerGesellschaftenSach from './components/VerteilungDerGesellschaftenSach';

const GesellschaftenPage = () => {
    return (
        <BasePage title="Gesellschaften">
            <VerteilungDerGesellschaftenKV />
            <VerteilungDerGesellschaftenLV />
            <VerteilungDerGesellschaftenSach />
        </BasePage>
    );
};

export default GesellschaftenPage;

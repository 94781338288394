import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import Loader from '../loader/Loader';

export type Option = {
    label: string;
    value: string;
    hasChildren: boolean;
    isParent: boolean;
    backValue: string;
}

type Props = {
    placeholder?: string;
    searchValue?: string;
    rootLabel?: string;
    selected?: string;
    isLoading: boolean;
    options: Option[];
    onChange: (value: string) => void;
    onSelect: (value: string) => void;
    onNavigate: (value: string) => void;
};

const DropdownSearch: React.FC<Props> = (
    {
        placeholder = '',
        searchValue,
        rootLabel,
        selected,
        isLoading,
        options,
        onChange,
        onSelect,
        onNavigate
    }
) => {
    const [opened, setOpened] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useClickAway(ref, () => {
        if (searchValue && options.length === 1) {
            const item = options[0];
            item && onSelect(item.value);
        }

        setOpened(false);
    });

    const getArrowRight = (id: string) => {
        return <svg className={'drp-option-icon'} onClick={() => onNavigate(id)}>
            <path
                d="M10.5313 7.46877C10.8251 7.76252 10.8251 8.23752 10.5313 8.52815L5.53135 13.5313C5.2376 13.825 4.7626 13.825 4.47197 13.5313C4.18135 13.2376 4.17822 12.7626 4.47197 12.4719L8.94072 8.00318L4.46885 3.5313C4.1751 3.23755 4.1751 2.76255 4.46885 2.47193C4.7626 2.1813 5.2376 2.17818 5.52822 2.47193L10.5313 7.4688V7.46877Z"
                fill="black" />
        </svg>;
    };

    const getArrowLeft = (id: string) => {
        return <svg className={'drp-option-icon'} onClick={() => onNavigate(id)}>
            <path
                d="M5.46865 8.53123C5.1749 8.23748 5.1749 7.76248 5.46865 7.47185L10.4687 2.46873C10.7624 2.17498 11.2374 2.17498 11.528 2.4687C11.8187 2.76245 11.8218 3.23745 11.528 3.52807L7.05928 7.99682L11.5312 12.4687C11.8249 12.7624 11.8249 13.2374 11.5312 13.5281C11.2374 13.8187 10.7624 13.8218 10.4718 13.5281L5.46865 8.5312V8.53123Z"
                fill="black" />
        </svg>;
    };

    return (
        <div ref={ref} className={'dr-dropdown-search' + (opened ? ' lgs--opened' : '')}>
            <div className={'drp-search-component'}>
                <input
                    className={'drp-input'}
                    placeholder={placeholder}
                    value={searchValue}
                    onClick={() => setOpened(true)}
                    onChange={(e) => onChange(e.target.value)}
                />
                <svg onClick={() => setOpened((prevState) => !prevState)} className="drp-icon">
                    <path d="M8.25 15L12 18.75L15.75 15M8.25 9L12 5.25L15.75 9" />
                </svg>
            </div>
            <ul>
                <Loader loading={isLoading} />
                {!isLoading && !options.length && <li className={'drs--empty'}>Nichts gefunden</li>}
                {!isLoading && !!options.length && options?.map((option) => (
                    <li
                        key={option.value}
                        className={`
                            ${option.value === selected ? 'lgs--active' : ''}
                            ${(option.isParent || option.label === rootLabel) ? 'drt--parent' : ''}
                        `}
                    >
                        {option.isParent && getArrowLeft(option.backValue)}
                        <div className={'drp-option'} onClick={() => {
                            onSelect(option.value);
                            setOpened(false);
                        }}>
                            <span className={'drp-option-value'}>{option.value}</span>
                            <span>{option.label}</span>
                        </div>
                        {option.hasChildren && !option.isParent && getArrowRight(option.value)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DropdownSearch;

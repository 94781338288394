import React, { useCallback, useEffect, useRef, useState } from 'react';

import BaseReport from './BaseReport';
import LineChart from '../chart/LineChart/LineChart';
import LineChartData from '../../models/LineChartData';
import Report from './Report';
import ReportsService from '../../services/ReportsService';
import DataTransformer from '../../utilities/DataTransformer';

const LineReport: React.FC<Report> = (
    {
        reportCode,
        brokerId,
        brokerReport,
        title,
        percentage,
        defaultFilter,
        filterOptions,
        ...props
    }
) => {
    const [data, setData] = useState<LineChartData>([]);
    const [yearFilter, setYearFilter] = useState<string>(defaultFilter || '');

    const mountedRef = useRef(false);

    const getData = useCallback(() => {
        return ReportsService.get(reportCode, brokerId, { year: yearFilter })
            .then(DataTransformer.toLineChartData)
            .then((data) => {
                mountedRef.current && setData(data);
            });
    }, [brokerId, reportCode, yearFilter]);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <BaseReport
            title={title}
            getCallback={getData}
            type="chart"
            reportCode={reportCode}
            data={brokerReport ? data[0] : data[1]}
            average={data[2]}
            filterValue={yearFilter}
            filterOptions={filterOptions}
            onFilterChange={(year) => mountedRef.current && setYearFilter(year)}
        >
            <LineChart
                data={brokerReport ? data[0] : data[1]}
                average={data[2]}
                valueSuffix={percentage ? '%' : ''}
                percentage={percentage}
                {...props}
            />
        </BaseReport>
    );
};

export default LineReport;

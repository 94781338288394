import React, { HTMLAttributes } from 'react';

type Props = {
    loading?: boolean;
    label?: string;
};

const Loader: React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({
    loading = true,
    label = '',
    className = '',
    ...props
}) => {
    if (!loading) {
        return null;
    }

    return (
        <div className={'dr-loader ' + className} {...props}>
            <div className="drp-spinner" />
            {label ? <span className="drp-label">{label}</span> : null}
        </div>
    );
};

export default Loader;

import React from 'react';

import ValueFilter from '../../../components/reports/ValueFilter';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageDataTableReport from '../../components/PageDataTableReport';
import { ValueFilterOptions } from './VerteilungDerGesellschaftenKV';

const VerteilungDerGesellschaftenLV = () => {
    const filterOptions = [...ValueFilterOptions].filter(o => o.value !== ValueFilter.Monthly);

    return (
        <PageDataTableReport
            title="Verteilung der Gesellschaften LV"
            reportCode={ReportCodes.DistributionOfCompaniesInLife}
            defaultFilter={ValueFilter.Evaluation}
            filterOptions = {filterOptions}
        />
    );
};

export default VerteilungDerGesellschaftenLV;

import React from 'react';

import NumberFormatter from '../../../utilities/NumberFormatter';

type Props = {
    number?: number;
    valueSuffix?: string;
    roundDigits?: boolean;
};

const NumberChart: React.FC<Props> = ({ number, valueSuffix = '', roundDigits = false }) => {
    const numberWithSuffix = (number: number) => {
        return NumberFormatter.format(number, roundDigits ? 2 : undefined) + valueSuffix;
    };

    return <>{number ? numberWithSuffix(number) : '-'}</>;
};

export default NumberChart;

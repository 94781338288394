import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageBarReport from '../../components/PageBarReport';

const VerteilungNachVertragsstatus = () => {
    return (
        <PageBarReport
            title="Verteilung nach Vertragsstatus"
            reportCode={ReportCodes.DistributionPerContractStatus}
            roundDigits
        />
    );
};

export default VerteilungNachVertragsstatus;

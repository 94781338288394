import BaseService from './BaseService';
import ReportData from '../models/ReportData';
import AuthContext from '../providers/AuthContext';

export type ResponseDatum = {
    v?: number;
    month: number;
    year: number;
    avg?: any;
    report?: {
        broker: any;
        children: any;
    } | null;
};

const transform = (response: ResponseDatum[]): ReportData => {
    return (response || [])
        .filter((a) => a?.report?.broker || a?.report?.children)
        .sort((a, b) => {
            if (a.year === b.year) {
                return a.month - b.month;
            }

            return a.year - b.year;
        })
        .map((datum) => ({
            period: (datum?.year && datum?.month) ? `${datum.year}-${datum.month.toString().padStart(2, '0')}` : '',
            report: { broker: datum.report?.broker || null, network: datum.report?.children || null },
            avg: datum.avg
        }));
};

const ReportsService = {
    get: (reportType: string, brokerId: string, params?: Record<string, string>): Promise<ReportData> => {
        const service = BaseService();
        const user = AuthContext.getUser();
        const brokerIdentifier = brokerId || user.activeBrokerId || user.id;
        const queryParams: string[] = [];

        if (params) {
            Object.keys(params).forEach((param) => {
                queryParams.push(`${param}=${params[param]}`);
            });
        }

        return service
            .get(`/reports/${reportType}/${brokerIdentifier}` + (queryParams.length ? `?${queryParams.join('&')}` : ''))
            .then(transform)
            .catch((error: any) => Promise.reject(error.response?.data || error));
    },

    getReportingYears: (): Promise<number[]> => {
        const service = BaseService();

        return service.get('/reports/years').catch((error: any) => Promise.reject(error.response?.data || error));
    }
};

export default ReportsService;

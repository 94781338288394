import TableChartData from '../models/TableChartData';

export const sortTableData = (data: TableChartData[]): TableChartData[] => {
    return data.map((datum) =>
        datum.sort((a, b) => {
            if (a.value === b.value) {
                return 0;
            }

            return a.value < b.value ? 1 : -1;
        })
    );
};

const SortingUtility = { sortTableData };

export default SortingUtility;

import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageLineReport from '../../components/PageLineReport';

const StornoquoteImHaftungszeitraum = () => {
    return (
        <PageLineReport
            title="Stornoquote im Haftungszeitraum"
            reportCode={ReportCodes.CancellationQuote}
            roundDigits
        />
    );
};

export default StornoquoteImHaftungszeitraum;

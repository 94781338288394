import React from 'react';

type Props = {
    label?: string;
    checked: boolean;
    onChecked: () => void;
};

const Checkbox: React.FC<Props> = ({ label, checked, onChecked }) => {
    return (
        <label onClick={onChecked} className="dr-check-box">
            <span className={'drp-check-mark' + (checked ? ' drs--checked' : '')}>
                <svg viewBox="0 0 12 9" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.57333 9C4.48192 8.99971 4.39154 8.98062 4.30782 8.94392C4.2241 8.90722 4.14882 8.85369 4.08666 8.78667L0.846663 5.34C0.725547 5.21093 0.660666 5.03903 0.666292 4.86212C0.671918 4.68521 0.747591 4.51778 0.876663 4.39667C1.00573 4.27555 1.17763 4.21067 1.35454 4.2163C1.53145 4.22192 1.69888 4.2976 1.82 4.42667L4.56666 7.35333L10.1733 1.22C10.2302 1.14915 10.301 1.09068 10.3813 1.04818C10.4616 1.00568 10.5498 0.980044 10.6404 0.972854C10.731 0.965665 10.8221 0.97707 10.9081 1.00637C10.9941 1.03567 11.0732 1.08224 11.1406 1.14323C11.2079 1.20422 11.2621 1.27834 11.2998 1.36103C11.3375 1.44372 11.3579 1.53324 11.3597 1.62409C11.3615 1.71495 11.3447 1.80521 11.3104 1.88934C11.276 1.97347 11.2249 2.0497 11.16 2.11333L5.06666 8.78C5.00509 8.84824 4.93007 8.90301 4.84632 8.94087C4.76257 8.97873 4.6719 8.99886 4.58 9H4.57333Z" />
                </svg>
            </span>
            <span className="drp-label">{label}</span>
        </label>
    );
};

export default Checkbox;

import React from 'react';
import * as XLSX from 'xlsx';

import DataTransformer from '../../../../utilities/DataTransformer';
import ValueFilter from '../../../reports/ValueFilter';

type Props = {
    type?: 'chart' | 'table';
    data: any;
    average?: any;
    filename: string;
    filterValue?: string;
};

const ExportButton: React.FC<Props> = ({ type, data, average, filename, filterValue }) => {
    const getFormattedData = () => {
        if (type === 'table') {
            return DataTransformer.toTableChartExportData(data, filterValue === ValueFilter.Percentage);
        }

        if (data[0]?.category) {
            return DataTransformer.toBarChartExportData(data);
        }

        return DataTransformer.toLineChartExportData(data, average);
    };

    const handleExport = () => {
        const ws = XLSX.utils.json_to_sheet(getFormattedData());
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob(
            [excelBuffer],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
        );

        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.download = filename;
        tempLink.click();
        tempLink.remove();
        URL.revokeObjectURL(url);
    };

    return (
        <button className={'dr-export-button'} onClick={handleExport}>Exportieren</button>
    );
};

export default ExportButton;

/**
 * Converts the string to the Base64 encoded string
 *
 * @param {string} data
 * @return {string}
 */
export const toBase64 = (data: string) => window.btoa(data);

/**
 * Converts a string from the Base64 encoded string.
 *
 * @param {string} data
 * @return {string}
 */
export const fromBase64 = (data: string) => window.atob(data);

/**
 * Generates a random string.
 *
 * @return {string}
 */
export const generateRandomString = (length = 64) => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
};

/**
 * Returns the base64-urlencoded sha256 hash for the PKCE challenge.
 *
 * @param {string} codeVerifier
 * @return {Promise<string>}
 */
export async function generateCodeChallenge(codeVerifier: string) {
    const digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));
    // @ts-ignore
    const chars = String.fromCharCode(...new Uint8Array(digest));

    return window.btoa(chars).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

export const objectToBase64 = (data: any) => toBase64(JSON.stringify(data));

export const objectFromBase64 = (data: string) => JSON.parse(fromBase64(data));

const EncodingUtility = {
    generateRandomString,
    generateCodeChallenge,
    objectFromBase64,
    objectToBase64,
    toBase64,
    fromBase64
};

export default EncodingUtility;

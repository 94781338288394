import React, { useState } from 'react';
import { useMount } from 'react-use';

import { usePageContext } from '../pages/BasePage/BasePageContext';
import DropdownSearch, { Option } from '../DropdownSearch/DropdownSearch';
import BrokerService from '../../services/BrokerService';
import AuthContext from '../../providers/AuthContext';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import Broker from '../../models/Broker';
import SubBroker from '../../models/SubBroker';

type Props = {
    selectedBrokerId: string;
    onChange: (value: string) => void;
};

const BrokerSearch: React.FC<Props> = ({ selectedBrokerId, onChange }) => {
    const { brokerCache, setBrokerCache } = usePageContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
    const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);
    const [activeOptions, setActiveOptions] = useState<Option[]>([]);
    const [activePlaceholder, setActivePlaceholder] = useState<string>('Untervermittler durchsuchen...');

    const getBrokerChildren = (brokerId: string, search?: string, cancelToken?: CancelToken): void => {
        if (brokerCache[brokerId] && !search) {
            if (!brokerCache[brokerId]?.children?.length && brokerId !== AuthContext.getUser().id) {
                return getBrokerChildren(brokerCache[brokerId].parentId || '');
            }

            return setActiveOptions(createOptions(brokerCache[brokerId]));
        }

        setIsLoading(true);
        BrokerService.getBrokerData(brokerId, search, cancelToken)
            .then((broker) => {
                if (!broker.id) {
                    return;
                }

                if (!broker?.children?.length && !search && brokerId !== AuthContext.getUser().id) {
                    return getBrokerChildren(broker.parentId || '');
                }

                !search && cacheBroker(brokerId!, broker);

                const options = createOptions(broker, !search);
                const selectedOption = options.find((o) => o.value === selectedBrokerId);
                if (selectedOption && !isRoot(selectedBrokerId)) {
                    setActivePlaceholder(selectedOption?.value + ' ' + selectedOption?.label);
                }

                setActiveOptions(options);
            })
            .catch((error) => !axios.isCancel(error) && console.error(error))
            .finally(() => setIsLoading(false));
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);
        searchTimer && clearTimeout(searchTimer);
        cancelToken && cancelToken.cancel();

        const source = axios.CancelToken.source();
        setCancelToken(source);
        setSearchTimer(setTimeout(
            () => getBrokerChildren(AuthContext.getUser().id || '', value.trim(), source.token),
            500
        ));
    };

    const cacheBroker = (parentId: string, broker: Broker) => {
        setBrokerCache((prev: Record<string, Broker>) => {
            prev[parentId] = broker;

            return { ...prev };
        });
    };

    const isRoot = (id: string | null) => (id === null || id === '' || id === AuthContext.getUser().id);

    const createOptions = (broker: Broker, addRoot: boolean = true): Option[] => {
        const options: Option[] = broker.children?.map((b) => (getOption(b, b.hasChildren, false))) || [];
        if (!isRoot(broker.id)) {
            options?.unshift(getOption(broker, !!broker.children, true));

            return options;
        }

        addRoot && options?.unshift(getOption({ ...broker, name: 'Me' }, false, false));

        return options;
    };

    const getOption = (broker: Broker | SubBroker, hasChildren: boolean, isParent: boolean) => ({
        value: broker.id,
        label: broker.name,
        hasChildren: hasChildren,
        isParent: isParent,
        backValue: broker.parentId || ''
    });

    const handleSelection = (id: string) => {
        const broker = activeOptions.find((o) => o.value === id);
        setActivePlaceholder(isRoot(id) ? 'Untervermittler durchsuchen...' : broker?.value + ' ' + broker?.label);
        setSearchValue('');
        getBrokerChildren(id);
        onChange(id);
    };

    const handleNavigation = (id: string) => {
        setSearchValue('');
        getBrokerChildren(id);
    };

    useMount(() => getBrokerChildren(selectedBrokerId));

    return (
        <DropdownSearch
            placeholder={activePlaceholder}
            selected={selectedBrokerId}
            searchValue={searchValue}
            onChange={handleSearch}
            onNavigate={handleNavigation}
            onSelect={handleSelection}
            options={activeOptions}
            isLoading={isLoading}
            rootLabel={'Me'}
        />
    );
};

export default BrokerSearch;

import React, { useState } from 'react';
import { useLocation, useMount } from 'react-use';

import OAuthService, { AuthError } from '../../services/OAuthService';
import AuthContext from '../../providers/AuthContext';
import Loader from '../../components/loader/Loader';

import logo from '../../components/layout/logo.svg';

const LoginPage: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const params = new URLSearchParams(window.location.search);

    const location = useLocation();

    const getAuthCode = () => {
        setLoading(true);
        // store the current page url, so we can redirect back once the auth is done
        localStorage.setItem('redirect_page', location.pathname || '/');

        OAuthService.getAuthCodeUrl().then((url) => {
            window.location.href = url;
        });
    };

    const getToken = () => {
        setLoading(true);
        OAuthService.getTokens(params)
            .then((authInfo) => {
                return OAuthService.getUserInfo(authInfo.access_token).then((user) => [
                    user,
                    authInfo[process.env.REACT_APP_API_TOKEN || 'access_token'],
                    authInfo
                ]);
            })
            .then(([user, token, authInfo]) => {
                // set user
                AuthContext.setUser({
                    token,
                    name: user?.name,
                    id: user?.broker_id,
                    activeBrokerId: user?.broker_id,
                    pictureUrl: user?.picture,
                    authInfo
                });

                // redirect to the previous URL
                window.location.href = localStorage.getItem('redirect_page') || '/';
            })
            .catch((error: AuthError | any) => {
                setError(error.message);
            })
            .finally(() => setLoading(false));
    };

    useMount(() => {
        if (params.has('code')) {
            getToken();
        } else {
            getAuthCode();
        }
    });

    return (
        <div className="dr-app dr-login-page">
            {!!error && (
                <div className="drp-container">
                    <img src={logo} alt="Analytics" />
                    <div className="drp-error">{error}</div>
                    <main>
                        <button onClick={getAuthCode}>Login</button>
                    </main>
                </div>
            )}
            <Loader className="drm--page-loader" loading={loading} />
        </div>
    );
};

export default LoginPage;

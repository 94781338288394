import React from 'react';

import BasePage from '../../components/pages/BasePage/BasePage';
import Besicherungsquote from './components/Besicherungsquote';
import Haftungsvolumen from './components/Haftungsvolumen';
import StornoquoteImHaftungszeitraum from './components/StornoquoteImHaftungszeitraum';
import VertragsdichtePrivatInBetreuung from './components/VertragsdichtePrivatInBetreuung';
import VerteilungNachVertragsstatus from './components/VerteilungNachVertragsstatus';
import MahnquoteVonVertragenImHaftungszeitraum from './components/MahnquoteVonVertragenImHaftungszeitraum';
import AnzahlUntervermittler from './components/AnzahlUntervermittler';

const BestandPage = () => {
    return (
        <BasePage title="Bestand">
            <div className="drt--two-columns">
                <Besicherungsquote />
                <Haftungsvolumen />
            </div>
            <StornoquoteImHaftungszeitraum />
            <div className="drt--two-columns">
                <VertragsdichtePrivatInBetreuung />
                <VerteilungNachVertragsstatus />
            </div>
            <MahnquoteVonVertragenImHaftungszeitraum />
            <AnzahlUntervermittler />
        </BasePage>
    );
};

export default BestandPage;

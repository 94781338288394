import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageBarReport from '../../components/PageBarReport';

const VertragsdichtePrivatInBetreuung = () => {
    return (
        <PageBarReport
            title="Vertragsdichte privat in Betreuung"
            reportCode={ReportCodes.ContractVolumeOfPrivateCare}
            roundDigits
            averageSum
        />
    );
};

export default VertragsdichtePrivatInBetreuung;

import React from 'react';

import { PageReport } from '../../components/reports/Report';
import { usePageContext } from '../../components/pages/BasePage/BasePageContext';
import NumberReport from '../../components/reports/NumberReport';

const PageNumberReport: React.FC<PageReport> = (props) => {
    const { brokerReport, brokerId } = usePageContext();

    return <NumberReport brokerId={brokerId} brokerReport={brokerReport} {...props} />;
};

export default PageNumberReport;

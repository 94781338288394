import BaseService from './BaseService';
import { generateCodeChallenge, generateRandomString } from '../utilities/EncodingUtility';
import AuthContext from '../providers/AuthContext';

export type AuthInfo = {
    authorization_endpoint: string;
    token_endpoint: string;
    userinfo_endpoint: string;
    end_session_endpoint: string;
    revocation_endpoint: string;
};

export type AuthError = {
    code: string;
    message: string;
    error?: any;
};

const config = {
    authUrl: process.env.REACT_APP_AUTH_URL || '',
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
    tokenType: process.env.REACT_APP_API_TOKEN || 'access_token',
    redirectUri: window.location.origin + '/oauth-callback',
    scope: process.env.REACT_APP_AUTH_SCOPE || ''
};

const getAuthInfo = (): Promise<AuthInfo> => {
    return BaseService(config.authUrl, false)
        .get('/.well-known/openid-configuration')
        .then((data: AuthInfo) => ({
            authorization_endpoint: data.authorization_endpoint.replace(config.authUrl, ''),
            token_endpoint: data.token_endpoint.replace(config.authUrl, ''),
            userinfo_endpoint: data.userinfo_endpoint.replace(config.authUrl, ''),
            end_session_endpoint: data.end_session_endpoint.replace(config.authUrl, ''),
            revocation_endpoint: data.revocation_endpoint.replace(config.authUrl, '')
        }));
};

let authInfo: AuthInfo;

const authInfoPromise = getAuthInfo().then((data) => {
    authInfo = data;
});

enum StorageKeys {
    State = 'pkce_state',
    CodeVerifier = 'pkce_code_verifier'
}

const OAuthService = () => {
    const getAuthCodeUrl = async (): Promise<string> => {
        await authInfoPromise;

        const state = generateRandomString();
        localStorage.setItem(StorageKeys.State, state);

        const codeVerifier = generateRandomString();
        localStorage.setItem(StorageKeys.CodeVerifier, codeVerifier);

        const codeChallenge = await generateCodeChallenge(codeVerifier);

        // Build the authorization URL
        return (
            config.authUrl +
            authInfo.authorization_endpoint +
            '?response_type=code&client_id=' +
            encodeURIComponent(config.clientId) +
            '&scope=' +
            encodeURIComponent(config.scope) +
            '&redirect_uri=' +
            encodeURIComponent(config.redirectUri) +
            '&state=' +
            encodeURIComponent(state) +
            '&code_challenge=' +
            encodeURIComponent(codeChallenge) +
            '&code_challenge_method=S256'
        );
    };

    const getTokens = async (
        queryParams: URLSearchParams
    ): Promise<{ access_token: string; id_token: string; refresh_token: string; [key: string]: any }> => {
        const state = localStorage.getItem(StorageKeys.State);
        if (!queryParams.has('state') || queryParams.get('state') !== state) {
            return Promise.reject({
                code: 'state_compromised',
                message:
                    'The state in challenge is compromised. This indicates a third party is intercepting the auth. Cannot continue.'
            });
        }

        await authInfoPromise;

        const params = {
            grant_type: 'authorization_code',
            client_id: config.clientId,
            code: queryParams.get('code') || '',
            redirect_uri: config.redirectUri,
            code_verifier: localStorage.getItem(StorageKeys.CodeVerifier) || ''
        };

        const service = BaseService(config.authUrl, false);

        return service
            .post(authInfo.token_endpoint, new URLSearchParams(params), {
                'Content-type': 'application/x-www-form-urlencoded'
            })
            .then((response) => {
                // when access token is successfully retrieved, we don't need the state in local storage anymore
                localStorage.removeItem(StorageKeys.State);
                localStorage.removeItem(StorageKeys.CodeVerifier);

                return { ...response, expirationDate: Date.now() + response.expires_in * 1000 };
            })
            .catch(() => {
                return Promise.reject({
                    code: 'fetch_token_error',
                    message: 'Could not fetch the access token. Please try again later.',
                    error: service.getLastResponse()
                });
            });
    };

    const getUserInfo = (accessToken: string) => {
        return BaseService(config.authUrl).get(authInfo.userinfo_endpoint, { Authorization: 'Bearer ' + accessToken });
    };

    const getAccessToken = async () => {
        const user = AuthContext.getUser();
        if (user?.authInfo) {
            if (Date.now() > user.authInfo.expirationDate + 60000) {
                // if token expires within the next 60 seconds or already expired, refresh the token
                await authInfoPromise;
                const params = {
                    grant_type: 'refresh_token',
                    refresh_token: user.authInfo.refresh_token,
                    client_id: config.clientId
                };

                const service = BaseService(config.authUrl, false);

                return service
                    .post(authInfo.token_endpoint, new URLSearchParams(params), {
                        'Content-type': 'application/x-www-form-urlencoded'
                    })
                    .then((response) => {
                        AuthContext.setUser({
                            ...user,
                            token: response[config.tokenType],
                            authInfo: { ...response, expirationDate: Date.now() + response.expires_in * 1000 }
                        });

                        return response[config.tokenType];
                    })
                    .catch(() => {
                        return Promise.reject({
                            status: 401,
                            code: 'fetch_token_error',
                            message: 'Could not fetch the access token. Please try again later.',
                            error: service.getLastResponse()
                        });
                    });
            }

            return Promise.resolve(user.token);
        }

        return Promise.reject({ status: 401, message: 'Access denied. Please login again.' });
    };

    const logout = (idToken: string) => {
        window.location.href =
            config.authUrl +
            '/' +
            authInfo.end_session_endpoint +
            '?post_logout_redirect_uri=' +
            encodeURIComponent(window.location.origin) +
            '&id_token_hint=' +
            idToken;
    };

    return {
        getAuthCodeUrl,
        getTokens,
        getAccessToken,
        getUserInfo,
        logout
    };
};

export default OAuthService();

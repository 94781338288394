import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageBarReport from '../../components/PageBarReport';

const VerhaltnisKundenPrivatGewerbe = () => {
    return (
        <PageBarReport
            title="Verhältnis Kunden privat/Gewerbe"
            reportCode={ReportCodes.CustomerDistributionPrivateBusiness}
            percentage
            roundDigits
        />
    );
};

export default VerhaltnisKundenPrivatGewerbe;

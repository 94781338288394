import React from 'react';
import { PageReport } from '../../components/reports/Report';
import { usePageContext } from '../../components/pages/BasePage/BasePageContext';
import DataTableReport from '../../components/reports/DataTableReport';

const PageDataTableReport: React.FC<PageReport> = (props) => {
    const { brokerReport, brokerId } = usePageContext();

    return <DataTableReport brokerId={brokerId} brokerReport={brokerReport} {...props} />;
};

export default PageDataTableReport;

import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageBarReport from '../../components/PageBarReport';

const VerteilungNachSpartenLV = () => {
    return (
        <PageBarReport
            title="Verteilung nach Sparten LV"
            reportCode={ReportCodes.DistributionPerSubSectionsForLifeInsurances}
        />
    );
};

export default VerteilungNachSpartenLV;

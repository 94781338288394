import React from 'react';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageBarReport from '../../components/PageBarReport';

const AltersdurchschnittKunden = () => {
    return (
        <PageBarReport
            title="Altersdurchschnitt Kunden"
            reportCode={ReportCodes.CustomerAge}
            roundDigits
            percentage
        />);
};

export default AltersdurchschnittKunden;

import BaseService from './BaseService';
import Broker from '../models/Broker';
import { CancelToken } from 'axios';

const BrokerService = {
    getBrokerData: (brokerId: string, search?: string, cancelToken?: CancelToken): Promise<Broker> => {
        const service = BaseService();
        let url = `/reports/children/${brokerId}`;
        if (search) {
            url += `?search=${search}`;
        }

        return service.get(url,{}, cancelToken).catch((error: any) => Promise.reject(error.response?.data || error));
    }
};

export default BrokerService;

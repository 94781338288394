import React from 'react';

import { usePageContext } from '../../pages/BasePage/BasePageContext';
import SelectBox from '../../SelectBox/SelectBox';
import ExportButton from './ExportButton/ExportButton';
import { ReportFilter } from '../../reports/Report';

type FooterProps = ReportFilter & {
    reportCode: string;
    type?: 'chart' | 'table' | 'number';
    data?: any[];
    average?: any;
};

const Footer: React.FC<FooterProps> = (
    {
        reportCode,
        type = 'chart',
        data = [],
        average,
        filterValue,
        filterOptions,
        onFilterChange
    }
) => {
    const { reportingYears } = usePageContext();
    const currentYear: number = new Date().getFullYear();
    const hasYearBeforeLast = !!reportingYears.filter((year) => year !== currentYear && year !== currentYear - 1).length;
    const breakOnOption = reportingYears.filter((year) => year === currentYear || year === currentYear - 1).length;

    if (type === 'number') {
        return null;
    }

    return (
        <footer>
            {onFilterChange && !!filterValue && filterOptions &&
                <SelectBox
                    prefix={`${type === 'chart' ? 'Zeitraum' : 'Wert'}: `}
                    value={filterValue}
                    options={filterOptions ?? []}
                    onChange={onFilterChange}
                    breakAfterOption={type === 'chart' && hasYearBeforeLast ? breakOnOption : 0}
                />
            }
            <ExportButton
                type={type}
                data={data}
                average={average}
                filename={reportCode + '_' + filterValue}
                filterValue={filterValue}
            />
        </footer>
    );
};

export default Footer;

import ReportData from '../models/ReportData';
import LineChartData, { Line2D, Point2D } from '../models/LineChartData';
import StackedBarData, { BarDataBlock } from '../models/StackedBarData';
import TableChartData from '../models/TableChartData';
import { RestItemsLabel } from '../constants';
import { translations } from '../components/chart/StackedBarChart/StackedBarChart';

type DataType = 'broker' | 'network';

export const toLineChartData = (data: ReportData): LineChartData => {
    if (!data?.length) {
        return [];
    }

    if (!data[0].report?.broker?.value && !data[0].report?.network?.value) {
        // data is multiline
        return toMultilineChartData(data);
    }

    const brokerLine: Line2D = { name: 'Vermittler', data: [] };
    const childrenLine: Line2D = { name: 'Untervermittler', data: [] };
    const averageLine: Line2D = { name: 'Durchschnitt', data: [] };

    data.forEach((month) => {
        brokerLine.data.push({ x: month.period, y: month.report.broker?.value || 0 });
        childrenLine.data.push({ x: month.period, y: month.report.network?.value || 0 });
        !!month.avg && averageLine.data.push({ x: month.period, y: month.avg });
    });

    const lines = [brokerLine, childrenLine];
    if (!!averageLine.data.length) {
        lines.push(averageLine);
    }

    return lines;
};

export const toMultilineChartData = (data: ReportData): LineChartData => {
    const mapData = (key: DataType, name: string): Line2D[] => {
        const lines: Line2D[] = [];
        const categories: string[] = Object.keys(data[0].report[key] || {});
        categories.forEach((category) => {
            const line: Point2D[] = data.map((month) => {
                return { x: month.period, y: month.report[key][category] };
            });

            lines.push({ name: `${name}-${category}`, data: line });
        });

        return lines;
    };

    return [...mapData('broker', 'Broker'), ...mapData('network', 'Network')];
};

const limitSeries = (series: BarDataBlock[]) => {
    const limit = 10;
    if (series.length > limit) {
        series = series.sort((a, b) => (a.value > b.value ? -1 : 1));
        let rest = 0;
        for (let i = limit; i < series.length; i++) {
            rest += series[i].value;
        }

        series = [...series.slice(0, limit), { name: RestItemsLabel, value: rest }];
    }

    return series.reverse();
};

export const toStackedBarData = (data: ReportData): StackedBarData[] => {
    if (!data?.length) {
        return [];
    }

    if (data[0].report?.broker?.[0]?.section || data[0].report?.network?.[0]?.section) {
        // data is fixed
        return toSectionStackedBarData(data);
    }

    const mapData = (key: DataType): StackedBarData => {
        const categoriesNames: string[] = Object.keys(data?.[0].report[key]);

        return data.map((month) => {
            let series: BarDataBlock[] = categoriesNames.map((category) => ({
                name: category,
                value: month.report[key]?.[category]
            }));

            return { category: month.period, series: limitSeries(series) };
        });
    };

    return [mapData('broker'), mapData('network')];
};

export const toSectionStackedBarData = (data: ReportData): StackedBarData[] => {
    const mapData = (key: DataType): StackedBarData => {
        return data.map((month) => {
            const series: BarDataBlock[] = (month.report[key] || []).map((category: any) => ({
                name: category.section,
                value: category.value
            }));

            return { category: month.period, series: limitSeries(series) };
        });
    };

    return [mapData('broker'), mapData('network')];
};

export const toNumberChartData = (data: ReportData): number[] => {
    if (!data?.length) {
        return [0, 0];
    }

    const report = data[data.length - 1].report;

    return [report.broker?.value || 0, report.network?.value || 0];
};

export const toTableChartData = (data: ReportData, valueProperty: string = 'value'): TableChartData[] => {
    if (!data?.length) {
        return [];
    }

    const report = data[data.length - 1].report;
    const mapData = (data: any[] | Record<string, any>): TableChartData => {
        return (
            data?.map((row: any) => ({
                name: row.companyName as string,
                value: row[valueProperty]
            })) || []
        );
    };

    return [mapData(report.broker), mapData(report.network)];
};

export const toLineChartExportData = (data: Line2D, average?: Line2D): any[] => {
    const formattedData = data.data?.map((point) => ({
        period: point.x,
        value: point.y
    }));

    if (average) {
        return  formattedData.map((point) => ({
            ...point,
            average: average.data.find((avgPoint) => avgPoint.x === point.period)?.y
        }));
    }

    return formattedData;
};

export const toBarChartExportData = (data: StackedBarData): any[] => {
    const exportData: { period: string, name: string, value: any }[] = [];
    data.forEach((category) => {
        category.series.forEach((serie) => {
            exportData.push({
                period: category.category,
                name: translations[serie.name] ?? serie.name,
                value: serie.value
            });
        });
    });

    return exportData;
};

export const toTableChartExportData = (data: TableChartData, percentage?: boolean): any[] => {
    if (percentage) {
        const sum = toDataValueSum(data);

        return data.map((item) => ({ ...item, value: (item.value * 100) / sum }));
    }

    return data;
};

export const toDataValueSum = (data: { value: any }[]) => {
    return data?.reduce((sum, item) => sum + item.value, 0) || 0;
};

// all "to chart data" methods return an array where
// the first item is the broker data, and
// the second item is the network data
const DataTransformer = {
    toLineChartData,
    toStackedBarData,
    toNumberChartData,
    toTableChartData,
    toLineChartExportData,
    toBarChartExportData,
    toTableChartExportData,
    toDataValueSum
};

export default DataTransformer;

import React from 'react';

import ValueFilter from '../../../components/reports/ValueFilter';
import ReportCodes from '../../../components/reports/ReportCodes';
import PageDataTableReport from '../../components/PageDataTableReport';
import { ValueFilterOptions } from './VerteilungDerGesellschaftenKV';

const VerteilungDerGesellschaftenSach = () => {
    const filterOptions = [...ValueFilterOptions].filter(
        o => o.value !== ValueFilter.Evaluation && o.value !== ValueFilter.Monthly
    );

    return (
        <PageDataTableReport
            title="Verteilung der Gesellschaften Sach"
            reportCode={ReportCodes.DistributionOfCompaniesInProperty}
            defaultFilter={ValueFilter.Yearly}
            filterOptions={filterOptions}
        />
    );
};

export default VerteilungDerGesellschaftenSach;

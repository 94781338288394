import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Auth from './auth/Auth';
import BasePageProvider from '../components/pages/BasePage/BasePageProvider';
import Page404 from '../components/pages/Page404';
import Layout from '../components/layout/Layout';
import BestandPage from './bestand/BestandPage';
import KundenPage from './kunden/KundenPage';
import SpartenPage from './sparten/SpartenPage';
import GesellschaftenPage from './gesellschaften/GesellschaftenPage';

const App = () => (
    <BrowserRouter>
        <Auth>
            <Layout>
                <BasePageProvider>
                    <Switch>
                        <Route exact path="/">
                            <BestandPage />
                        </Route>
                        <Route exact path="/kunden">
                            <KundenPage />
                        </Route>
                        <Route exact path="/sparten">
                            <SpartenPage />
                        </Route>
                        <Route exact path="/gesellschaften">
                            <GesellschaftenPage />
                        </Route>
                        <Route exact path="/oauth-callback">
                            <Redirect to="/" />
                        </Route>
                        <Route>
                            <Page404 />
                        </Route>
                    </Switch>
                </BasePageProvider>
            </Layout>
        </Auth>
    </BrowserRouter>
);

export default App;
